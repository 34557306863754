import React from "react"
import { navigate } from "gatsby"

const EventsPage = () => {
  if (typeof window !== "undefined") {
    navigate("/events/sevenoaks-spring-clean")
  }
  return null
}

export default EventsPage
